
// Generated with:
//   grep -v '%#' tmpl/mojo/api/map/style/standard_layers.json.ep | jq '[ .[] | select(.layout["text-field"] // "" | contains("name")) | .id ] | sort'
const nameLayers = [
    "airport-label",
    "country_label",
    "country_label-other",
    "place-islands",
    "place_label_city_big",
    "place_label_city_town_medium",
    "place_label_other",
    "place_village_suburb",
    "poi_major",
    "poi_minor",
    "poi_railway",
    "road_major_label",
    "water_label",
    "waterway-label"
];


export default class DetectLanguage {
    constructor() {
        this._onStyleData = this._onStyleData.bind(this);
    }

    onAdd(map) {
        this.map = map;
        this.map.on('styledata', this._onStyleData);
        this._container = document.createElement('div');
        return this._container;
    }

    onRemove() {
        this.map.off('styledata', this._onStyleData);
    }

    _onStyleData() {
        this.map.off('styledata', this._onStyleData);

        const language = document.documentElement.lang.substr(0, 2);
        if (language) {
            const value = ['coalesce', ['get', 'name:' + language], ['get', 'name'] ];
            for (let id of nameLayers) {
                this.map.setLayoutProperty(id, 'text-field', value);
            }
        }
    }
}
