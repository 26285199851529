import { LngLatBounds } from 'maplibre-gl';
import { coordEach } from '@turf/meta';
import { featureCollection } from '@turf/helpers';

/**
 * Calculate bounds that contains the given GeoJSON features.
 * @param {(FeatureCollection|Feature|Geometry|Array)} geojson - A GeoJSON
 * object. An array is converted to a feature collection.
 */
export function featureBounds(geojson) {
    if (Array.isArray(geojson)) {
        geojson = featureCollection(geojson);
    }
    const bounds = new LngLatBounds();
    coordEach(geojson, c => bounds.extend(c));
    return bounds;
}

export function getMapColors(map) {
    const computedStyle = getComputedStyle(map.getContainer());
    const getCssColor = (name) => computedStyle.getPropertyValue(`--${name}`);

    return {
        country:       getCssColor('map-marker-country-color'),
        region:        getCssColor('map-marker-region-color'),
        municipality:  getCssColor('map-marker-municipality-color'),
        zipcode:       getCssColor('map-marker-zipcode-color'),
        fallback:      getCssColor('map-marker-fallback-color'),
        cluster:       getCssColor('map-marker-cluster-color'),
        selected:      getCssColor('map-marker-selected-color'),
        address_count: getCssColor('map-marker-address-count-color'),
        radius_circle: getCssColor('map-marker-radius-circle-color'),
    };
}
