import $ from 'jquery';

import RadiusMap from 'JIX/map/RadiusMap.js';

const JIX = window.JIX = window.JIX || {};
JIX.Jobsearch = JIX.Jobsearch || {};
JIX.Jobsearch.RadiusMap = {};

function radius_map_update() {
    const $container = $("#radius_map");
    if ($container.length === 0) {
        return;
    }

    const radius = $container.data('radius');
    const lat = $container.data('latitude') / 1;
    const lng = $container.data('longitude') / 1;

    const map = new RadiusMap({ container: $container[0] });
    map.updatePoint([lng, lat], radius);
}

$(() => {
    radius_map_update();
    $(document).on('reloaded.jix.jobsearch', () => {
        radius_map_update();
    });
});
