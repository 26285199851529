import { watch, onBeforeUnmount } from 'vue';

/**
 * Add event listener to element contained in a reactive Ref. That is, every
 * time the element in the Ref changes that event listener is removed from the
 * old element and added to the new element. The event listener is also removed
 * when the component is unmounted.
 *
 * Type:
 * function useRefEventListener(
 *     elementRef: Ref<Element | null>,
 *     eventName: string,
 *     listener: (event: Event) => void
 * ): void
 */
export function useRefEventListener(elementRef, eventName, listener) {
    watch(elementRef, (newEl, oldEl) => {
        oldEl?.removeEventListener(eventName, listener);
        newEl?.addEventListener(eventName, listener);
    }, { immediate: true });
    onBeforeUnmount(() => elementRef.value?.removeEventListener(eventName, listener));
}
