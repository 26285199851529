import { ref } from 'vue';

import { useRefEventListener } from 'JIX/eventListener.js';

const breakpointValues = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

/**
 * Returns a ref that updates when a given responsive breakpoint matches.
 *
 * Type:
 * function useMatchesBreakpoint<K extends keyof breakpointValues>(
 *     breakpoint: K,
 * ): Ref<boolean>
 */
export function useMatchesBreakpoint(breakpoint) {
    const res = ref();
    // Since tests also mounts components, window will not exist during test execution
    if (typeof window.matchMedia === "function"){
        const matchMedia = window.matchMedia(`(min-width: ${breakpointValues[breakpoint]}px)`);
        res.value = matchMedia.matches;
        useRefEventListener(ref(matchMedia), 'change', (event) => res.value = event.matches);
    }
    return res;
}
