import { circle } from '@turf/circle';
import { featureCollection, point } from '@turf/helpers';

import DefaultMap from './DefaultMap.js';
import { api } from 'JIX/utils.js';
import { featureBounds, getMapColors } from './utils.js';

function getLayers(colors) {
    return [
        {
            id: 'radius_circle',
            type: 'fill',
            source: 'radius',
            filter: ['==', '$type', 'Polygon'],
            paint: {
                'fill-opacity': 0.4,
                'fill-color': colors.radius_circle,
            }
        },
        {
            id: 'radius_outline',
            type: 'line',
            source: 'radius',
            filter: ['==', '$type', 'Polygon'],
            paint: {
                'line-width': 1,
                'line-color': colors.radius_circle,
            }
        },
        {
            id: 'radius_center',
            type: 'circle',
            source: 'radius',
            filter: ['==', '$type', 'Point'],
            paint: {
                'circle-radius': 3,
                'circle-opacity': 1,
                'circle-stroke-color': '#fff',
                'circle-stroke-width': 1,
            }
        },
    ];
}

class InvalidAddressError extends Error {
    constructor(address) {
        super(`Invalid address: ${address}`);
        this.name = 'InvalidAddressError';
        this.address = address;
    }
}

/**
 * Convert address into lng/long coordinates.
 */
function geocode(address) {
    const url = '/api/geo/v1/validate-address?address=' + encodeURIComponent(address);

    return api('GET', url)
        .then(json => {
            if (!json.results.valid) {
                throw new InvalidAddressError(address);
            }
            return json.results.pos;
        });
}

export default class RadiusMap extends DefaultMap {
    constructor(options) {
        super(options);
        this.on('load', this._onMapLoaded.bind(this));
    }

    /**
     * Update center and radius of circle.
     */
    updatePoint(center, radius) {
        this._center = center;
        this._radius = radius;
        this._updateData();
        this.refitBounds();
    }

    /**
     * Update only radius of circle
     */
    updateRadius(radius) {
        this.updatePoint(this._center, radius);
    }

    /**
     * Update center and radius from an address and radius.
     */
    updateAddress(address, radius) {
        if (this._address === address) {
            this.updateRadius(radius);
        } else {
            this._address = address;
            geocode(address)
                .then(({ lng, lat }) => this.updatePoint([lng, lat], radius))
                .catch(err => {
                    this.removeMarker();
                    console.error(err);
                    if (!(err instanceof InvalidAddressError)) {
                        window.Sentry.captureException(err);
                    }
                });
        }
    }

    /**
     * Update map bounds such that the radius circle is completely visible.
     */
    refitBounds(animate = false) {
        if (this._circleFeature) {
            const bounds = featureBounds(this._circleFeature);
            // Assume that there are controls at the right edge
            this.fitBounds(bounds, { padding: { top: 20, left: 20, bottom: 20, right: 50 }, animate });
        }
    }

    /**
     * Remove circle from map.
     */
    removeMarker() {
        this._circleFeature = null;
        this._address = null;
        this._center = null;
        this._radius = null;
        this._updateData();
    }

    _onMapLoaded() {
        const colors = getMapColors(this);

        this.addSource('radius', {
            type: 'geojson',
            data: featureCollection([]),
        });
        getLayers(colors).forEach(l => this.addLayer(l, 'poi_major'));
        this.setLayoutProperty('highway_shield', 'visibility', 'none');
        this._updateData();
        this.refitBounds();
    }

    _updateData() {
        let source;
        try {
            source = this.getSource('radius');
        } catch (e) {
            return;
        }
        if (source) {
            const features = [];
            if (this._center && this._radius) {
                this._circleFeature = circle(this._center, this._radius);
                features.push(this._circleFeature);
            }
            if (this._center) {
                features.push(point(this._center));
            }
            source.setData(featureCollection(features));
        }
    }
}
